import { combineReducers } from 'redux';
import metadata from './metadata/reducer';
import accountsActivityByType from './accountActivityByType/reducer';
import loginsByUSContacts from './loginsByUSContacts/reducer';
import loginsByGlobalContacts from './loginsByGlobalContacts/reducer';
import totalViewTimeByDate from './totalViewTimeByDate/reducer';
import totalUniqueLoginsByDate from './totalUniqueLoginsByDate/reducer';
import totalViewTimeBySlide from './totalViewTimeBySlide/reducer';
import componentsStats from './componentsStats/reducer';
import accountsActivity from './accountsActivity/reducer';
import downloadExcel from './downloadExcel/reducer';

export default combineReducers({
  metadata,
  accountsActivityByType,
  loginsByUSContacts,
  loginsByGlobalContacts,
  totalViewTimeByDate,
  totalUniqueLoginsByDate,
  totalViewTimeBySlide,
  componentsStats,
  accountsActivity,
  downloadExcel,
});
