import {
  ACTIVATION_EMAIL_RESEND_CALLBACK,
  EVERCALL_DASHBOARD_FORCE_LOGOUT_CALLBACK,
  VERIFICATION_EMAIL_RESEND_CALLBACK,
} from '@/Framework/Message/callbackTypes';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import config from '@/Framework/config';

const FINSIGHT_SUPPORT_EMAIL = config.tenant.finsight.supportEmail;

const CONTACT_LINK = `<a href='${ finsightWebUrl.getContactUsUrl() }' target="_blank">contact</a>`;
const CONTACT_US_LINK = `<a href='${ finsightWebUrl.getContactUsUrl() }' target="_blank">contact us</a>`;
const CONTACT_SUPPORT = `Please ${ CONTACT_LINK } our fanatical 24x7 support to troubleshoot.`;

const messages = {
  CONTACT_SUPPORT,
  GENERAL_ERROR: `An error has occurred and has been reported to our engineering team. Please try again and if the issue persists ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  SECURITY_LOG_OUT: 'For security reasons, you have been logged out due to inactivity.',

  UPLOAD_MAX_SIZE: 'The max file size supported is {{size}} {{units}}.',
  UPLOAD_INVALID_EXTENSION: '{{fileName}} has an invalid extension and cannot be uploaded. Valid extension(s) are: {{validExtensions}}.',
  UPLOAD_ONLY_ONE_FILE_ALLOWED: 'Only one {{uploadName}} may be uploaded',
  UPLOAD_SIMULTANEOUS_LIMIT: `For the best experience, we cannot process more than {{limit}} simultaneous file uploads.
                Due to this, {{notUploaded}} {{addText}} not been uploaded. Please verify and retry upload.`,
  UPLOAD_ZERO_SIZE: '{{fileName}} is empty and cannot be uploaded.',
  UPLOAD_INVALID_FOLDERS_NAMES: 'Folders "{{foldersNames}}" and their contents cannot be uploaded due to invalid folder name. Please change folder names and try again.',
  UPLOAD_INVALID_FOLDER_NAME: 'Folder "{{folderName}}" and its contents cannot be uploaded due to invalid folder name. Please change this folder name and try again.',
  UPLOAD_FOLDERS_UNSUPPORTED_BROWSER: 'Your browser does not support the uploading of folders. If you would like to upload folders, please switch browsers.',

  /*
   * Investor
   * */
  INVESTOR_ACCESS_RESTRICTED: `Access to this roadshow is restricted. If you require assistance, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  INVESTOR_LOGIN_LIMIT_EXCEED: `Access is restricted for this roadshow. You have exceeded allowable number of logins. ${ CONTACT_SUPPORT }`,
  INVESTOR_VERIFICATION_EMAIL_SENT: `Verification email sent to {{email}}.<br /><br />
        For security, we require email verification. If you do not receive this email within 2 minutes, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  INVESTOR_EMAIL_NOT_VERIFIED: `This email address has not been verified.
        To resend the verification email, <a onclick="execCallback('${ VERIFICATION_EMAIL_RESEND_CALLBACK }','{{callback_params}}')">click here</a>.
        If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  INVESTOR_MOBILE_RESTRICTED: 'Mobile access to this roadshow is disabled. Please use a desktop browser to view this presentation.',
  INVESTOR_NOT_CORPORATE_EMAIL: `For security, we require that you use your corporate email. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  INVESTOR_INACTIVE: 'You are currently inactive. To prevent being logged out, interact with the page.',
  INVESTOR_DISCLAIMER_REMOVED: 'Custom disclaimer was successfully removed for {{email}}',
  INVESTOR_DISCLAIMER_SAVED: 'Custom disclaimer was successfully added for {{email}}',

  /*
   * Roadshow
   * */
  ROADSHOW_CREATED: 'You successfully created the <strong>{{roadshowName}}</strong> roadshow!',
  ROADSHOW_UPDATED: 'You successfully updated the <strong>{{roadshowName}}</strong> roadshow!',
  ROADSHOW_NOTIFIED_CONTACTS: 'You successfully notified {{contacts}} who viewed the <strong>{{roadshowName}}</strong> roadshow!',
  ROADSHOW_NOTIFIED_ALL_CONTACTS: 'You successfully notified all contacts who viewed the <strong>{{roadshowName}}</strong> roadshow!',
  ROADSHOW_NOTIFIED_ALL_CONTACTS_HAS_NO_CONTACTS: 'No contacts have viewed this roadshow',
  ROADSHOW_INACCESSIBLE: 'Entry Code is either not enabled or invalid. Please note that entry codes are case and time sensitive.',
  ROADSHOW_ACCESS_DENIED: `You do not have access to this roadshow. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  ROADSHOW_PERMISSION_DENIED: `You do not have access to perform this action. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  ROADSHOW_VIEW_TIME_LIMIT_REACHED: `You have exceeded the time allotted for this roadshow. For security reasons, you have been logged out. ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  ROADSHOW_LIVE_VIDEO_DIAL_IN_DISCONNECTED: 'You are on mute. The phone you were using for audio was disconnected.',
  ROADSHOW_EVERCALL_CHAT_ID_WAS_NOT_BOOKED: 'Roadshow evercall chat id was not booked',
  ROADSHOW_EVERCALL_CHAT_ID_DUPLICATE: '{{code}} is not allowed',
  ROADSHOW_EVERCALL_CHAT_ID_INVALID_LENGTH: 'Code must contain 3-6 characters',
  ROADSHOW_EVERCALL_CHAT_ID_INVALID_CODE: 'Code must be alphanumeric',
  ROADSHOW_KICKED_USER: 'You have been removed from the “{{roadshowName}}” roadshow by a Deal Manager.',
  EVERCALL_OACC_CONFERENCE_PARTICIPANT_NOT_FOUND: 'Evercall conferenece participant not found',
  ROADSHOW_EVERCALL_MICROPHONE_ACCESS: 'In order to use Listen via Web feature please enable access to your microphone in the browser settings.',
  EVERCALL_ADMIN_CONFERENCE_ALREADY_LINKED: 'This Participant Conference ID is already linked to an Evercall',
  EVERCALL_ADMIN_ROADSHOW_ALREADY_LINKED: 'This Roadshow is already linked to an Evercall',
  EVERCALL_NO_ACCESS_TO_PROFILE: 'You are not allowed to create Evercalls',
  EVERCALL_NO_ACCESS_TO_UPLOAD_ANALYTICS: 'You are not allowed to upload analytics',
  EVERCALL_ACCESS_DENIED: `You do not have access to this Evercall. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  EVERCALL_RESEND_ERROR: 'Failed to resend message due to speaker\'s inactivity',
  EVERCALL_LOGIN_DENIED_DUE_TO_MULTIPLE_OPEN_TABS: `An error occured due to multiple open tabs. Please <a onclick="execCallback('${ EVERCALL_DASHBOARD_FORCE_LOGOUT_CALLBACK }', '{{callback_params}}')">click here to log out of all active Evercall dashboard tabs</a> and log in again on a new tab to access the dashboard.`,
  EVERCALL_DUPLICATE_SUCCESS: 'Events have been successfully scheduled',
  EVERCALL_ACCESS_REVOKED: 'Access for {{contactName}} ({{contactEmail}}) to this event has been successfully revoked.',
  EVERCALL_ACCESS_RESTORED: 'Access for {{contactName}} ({{contactEmail}}) to this event has been successfully restored.',
  EVERCALL_PARTICIPANT_ACCESS_APPROVED: 'Access for {{contactName}} ({{contactEmail}}) to this event has been successfully approved. Confirmation details with the event access details have been sent to the this participant.',
  EVERCALL_PARTICIPANT_ACCESS_DENIED: 'Access for {{contactName}} ({{contactEmail}}) to this event has been successfully denied.',
  ROADSHOW_EXPORT_VIDEO_REPLAY_START: 'Your export is being generated and will be sent as a downloadable link to support@dealroadshow.com when ready. This may take a moment.',
  ROADSHOW_REPLAY_VIDEO_EXPORT_ALREADY_IN_PROGRESS: 'Export is in progress. Please wait for the export to complete to regenerate the export.',
  // INVESTOR QUESTION
  ROADSHOW_INVESTOR_QUESTION_CHANGE_STATUS: 'You have {{status}} {{count}} questions',
  ROADSHOW_INVESTOR_QUESTION_CHANGE_STATUS_SINGLE: 'You have {{status}} a question from {{contactTitle}} at {{accountTitle}}',
  ROADSHOW_INVESTOR_QUESTIONS_NEW: '{{count}} new questions were added. Press "Open" tab to view them.',
  // REQUEST MEETINGS
  ROADSHOW_REQUEST_MEETINGS_DICLINE: 'You have declined a meeting with {{contactTitle}} at {{accountTitle}}',
  ROADSHOW_REQUEST_MEETINGS_SCHEDULED: 'You have successfully scheduled a meeting with {{contactTitle}} at {{accountTitle}}. Note: Meeting details will be emailed to the Investor. We strongly recommend sending a calendar invite for final confirmation.',

  /*
   * Dataroom
   * */
  DATAROOM_ALERTS_UPDATE: 'Success! Alerts {{userString}} have been edited.',
  DATAROOM_USER_NOT_ADDED_TO_DATAROOM: 'Error! User is not added to that dataroom.',
  DATAROOM_USER_HAS_NO_ACCESS_GROUP: `You do not have access to this data room. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  DATAROOM_USER_HAS_NO_ACCESS_GROUP_TO_PAGE: `You do not have access to this data room's page. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  DATAROOM_DEACTIVATED_DATAROOM_VIOLATION: 'Error! Deactivated dataroom violation.',
  DATAROOM_USER_COMPOSE_SUCCESS_ALL_USERS: 'Success! The email was sent to all users',
  DATAROOM_USER_COMPOSE_SUCCESS_USERS: 'Success! The email was sent to {{usersCount}} users',
  DATAROOM_USER_COMPOSE_SUCCESS_USER: 'Success! The email was sent to {{email}}',
  DATAROOM_USER_COMPOSE_SUCCESS_NOT_INVITED: 'Success! The email was sent to users who have not been invited',
  DATAROOM_USERS_DELETE_SUCCESS: 'Success! {{usersStringPrefix}} been deleted',
  DATAROOM_USERS_DELETE_ERROR: 'Error! {{usersStringPrefix}} not been deleted. Please try again or contact Support.',
  DATAROOM_USERS_ADD_SUCCESS: 'Success! {{usersStringPrefix}} been added to the {{dataroomName}} and {{emailInvitationString}} sent. Additional edits for {{usersStringPostfix}} can be made from the Manage Users table.',
  DATAROOM_USERS_ADD_ERROR: 'Error! User has not been added. Please try again or contact Support.',
  DATAROOM_CREATION_ERROR: 'Error! Dataroom has not been created.',
  DATAROOM_CREATION_INPUT_VALIDATION_ERROR: 'Error! Dataroom has not been created.',
  DATAROOM_CREATION_SAVE_ERROR: 'Error! Dataroom has not been created.',
  DATAROOM_PLAN_NOT_FOUND: 'Dataroom plan not found.',
  DATAROOM_UPDATE_UPLOAD_NOTIFICATION_SETTINGS_ERROR: 'Error! Alerts for {{userString}} have not been edited. Please try again or contact Support.',
  DATAROOM_UPDATE_DIGEST_NOTIFICATION_SETTINGS_ERROR: 'Error! Alerts for {{userString}} have not been edited. Please try again or contact Support.',
  DATAROOM_USERS_NOT_ADDED_TO_DATAROOM_ERROR: 'Error! Users are not added to that dataroom.',
  DATAROOM_LOGO_PROCESSING_ERROR: 'Error! Logo upload failed.',
  DATAROOM_LOGO_NOT_FOUND_ERROR: 'Error! Logo not found.',
  DATAROOM_DEACTIVATED_ARCHIVE_NOT_FOUND: 'Requested archive not found.',
  DATAROOM_DEACTIVATED_ARCHIVE_NO_ACCESS: 'You have no access to requested archive.',
  DATAROOM_DEACTIVATED_ARCHIVE_EXPIRED: 'The archive you are trying to download is no longer available.',
  DATAROOM_ANALYTICS_EXPORT_TO_EMAIL_SUCCESS: 'Your analytics are being prepared for download. Depending on total activity, this may take several minutes. Once ready, they will be emailed to {{email}}.',
  DATAROOM_ANALYTICS_USER_HAS_NO_ACCESS: `You do not have access to this data room’s analytics. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  INVALID_ARGUMENTS: 'Error! Invalid Arguments.',
  DATAROOM_SUCCESSFULLY_UNFOLLOWED: 'You successfully unfollowed {{dataroomName}}! This {{label}} has been removed from your {{title}} list.',
  DATAROOM_CREATE_FOLDER_SUCCESS: 'New folder created. Folder named "{{name}}" has been created.',
  DATAROOM_CREATE_FOLDERS_SUCCESS: 'New folders created. {{count}} new folders have been created.',
  DATAROOM_CREATE_FOLDERS_ERROR: 'Error! Folders named {{folderNamesPrefix}} not been created. Please try again or contact Support.',
  DATAROOM_SETTINGS_SAVE_SUCCESS: 'Success! {{prefix}} changes have been saved',
  DATAROOM_REMOVE_RECORDS_FROM_WHITE_LIST_SUCCESS: '{{prefix}} been deleted from the whitelist',
  DATAROOM_REMOVE_RECORDS_FROM_WHITE_LIST_ERROR: '{{prefix}} not deleted from the whitelist. Please try again or contact Support.',
  DATAROOM_ADD_TO_WHITELIST_SUCCESS: '{{prefix}} been added to the whitelist',
  DATAROOM_ADD_TO_WHITELIST_ERROR: '{{prefix}} NOT been added to the whitelist. Please try again or contact Support.',
  DATAROOM_ADD_TOP_ACCOUNTS_SUCCESS: 'Success! Top accounts have been added to the whitelist.',
  DATAROOM_REMOVE_RECORDS_FROM_TRASH_SUCCESS: 'Success! {{prefix}} been deleted.',
  DATAROOM_REMOVE_RECORDS_FROM_TRASH_ERROR: 'Error! {{prefix}} not been deleted. Please try again or contact Support.',
  DATAROOM_APPROVE_SUCCESS: '{{usersStringPrefix}} been added to the {{dataroomName}} and {{emailInvitationString}} sent. Additional edits for {{usersStringPostfix}} can be made from the Manage Users table.',
  DATAROOM_APPROVE_ERROR: '{{usersStringPrefix}} not been added. Please try again or contact Support.',
  DATAROOM_DENY_SINGLE_SUCCESS: '{{userStringPrefix}} has been denied access to {{dataroomName}}. Access Denied email was sent to the user.',
  DATAROOM_DENY_SINGLE_SUCCESS_WITHOUT_NOTIFICATION: '{{userStringPrefix}} has been denied access to {{dataroomName}}.',
  DATAROOM_DENY_MULTIPLE_SUCCESS: '{{usersStringPrefix}} users were denied access to {{dataroomName}} and were not added to the {{tenantLabel}}.',
  DATAROOM_DENY_ERROR: '{{usersStringPrefix}} NOT been denied access to {{dataroomName}}. Please try again or contact Support.',
  DATAROOM_ACCESS_DENIED: `Your access to this InvestorSet is denied. Please ${ CONTACT_LINK.toLocaleLowerCase() } us if you believe this is an error.`,
  DATAROOM_REVERT_SUCCESS: 'Success! {{usersStringPrefix}} reverted from Access Denied to Access Pending status. The {{usersStringPostfix}} not been added to {{dataroomName}}.',
  DATAROOM_REVERT_ERROR: 'Error! {{usersStringPrefix}} NOT reverted from Access Denied to Access Pending status. Please try again or contact Support.',
  DATAROOM_BULK_DOWNLOADING_SAVE_ERROR: 'Error! Bulk downloading were not saved. Please try again or contact Support.',
  DATAROOM_EMAIL_UPLOADING_SAVE_ERROR: 'Error! Email Uploading were not saved. Please try again or contact Support.',
  DATAROOM_PERMISSION_GROUP_ALREADY_EXIST: 'Error! A permission group with the name "{{pgName}}" already exists in the data room',
  DATAROOM_SAVE_COMPARE_PERMISSION_GROUPS_SUCCESS: 'Your changes have been successfully saved',
  DATAROOM_SAVE_COMPARE_PERMISSION_GROUPS_SINGLE_FAILED: `{{prefix}} permission group failed to save. Please, try again or ${ CONTACT_LINK.toLocaleLowerCase() } support.`,
  DATAROOM_SAVE_COMPARE_PERMISSION_GROUPS_MULTIPLE_FAILED: `The following permission groups failed to save: {{prefix}}. Please, try again or ${ CONTACT_LINK.toLocaleLowerCase() } support.`,
  DATAROOM_FILE_FOLDER_NAME_ALREADY_EXIST: 'A {{type}} with the name "{{name}}" already exist in the current folder.',
  DATAROOM_RESERVED_FOLDER_NAME: 'The folder name "{{name}}" in the root folder is reserved.',
  DATAROOM_REQUEST_PHYSICAL_COPY_SUCCESS: 'Physical copy was successfully requested!',
  DATAROOM_DOWNLOAD_ARCHIVE_MESSAGE: 'Your archive download will begin automatically.',
  // TO DO:
  // DATAROOM_USER_NOTIFICATION_MANAGE_ERROR: `User cannot manage notification settings of other users.`,

  DATAROOM_COPY_SUCCESS: 'Success! {{prefix}} been copied.',
  DATAROOM_RESTORE_SUCCESS: 'Success! {{prefix}} been restored.',
  DATAROOM_COPY_ERROR: '{{prefix}} not been copied. Please try again or contact Support.',
  DATAROOM_RESTORE_ERROR: 'Error! {{prefix}} not been restored. Please try again or contact Support.',
  DATAROOM_MOVE_SUCCESS: 'Success! {{prefix}} been moved.',
  DATAROOM_MOVE_ERROR: '{{prefix}} not been moved. Please try again or contact Support.',
  DATAROOM_CREATE_LINK_SUCCESS: 'New Link "{{name}}" has been created.',
  DATAROOM_REMOVE_FILESYSTEM_ITEMS_SUCCESS: '{{prefix}} been deleted.',
  DATAROOM_REMOVE_FILESYSTEM_ITEMS_ERROR: '{{prefix}} not been deleted. Please try again or contact Support.',
  DATAROOM_PERMISSION_GROUP_CREATE_SUCCESS: 'Success! {{prefix}} has been added.',
  DATAROOM_PERMISSION_GROUP_UPDATE_SUCCESS: 'Success! Changes to {{prefix}} have been saved.',
  DATAROOM_PERMISSION_GROUPS_EDIT_SUCCESS: 'Success! Permissions for {{userString}} have been edited.',
  DATAROOM_BULK_SAVE_PERMISSION_GROUPS_NOTIFICATION: 'Your {{prefix}} permissions is being prepared for the update. Depending on the number of permission groups and files, this may take several minutes',
  DATAROOM_REVOKE_PERMISSION_GROUPS_SUCCESS: 'Success! Access for {{userString}} been revoked.',
  DATAROOM_REVERT_PERMISSION_GROUPS_SUCCESS: 'Success! Access for {{userString}} been granted.',
  DATAROOM_REMOVE_PERMISSION_GROUPS_SUCCESS: 'Success! {{prefix}} been deleted.',
  DATAROOM_REMOVE_PERMISSION_GROUPS_ERROR: 'Error! {{prefix}} not been deleted.',
  DATAROOM_INDEX_UPDATE_SUCCESS: 'Index successfully updated',
  DATAROOM_INDEX_PIN_SUCCESS: 'Index successfully pinned',
  DATAROOM_INDEX_UNPIN_SUCCESS: 'Index successfully unpinned',
  DATAROOM_ITEMS_PIN_SUCCESS: 'Item(s) successfully pinned',
  DATAROOM_ITEMS_UNPIN_SUCCESS: 'Item(s) successfully unpinned',
  DATAROOM_FILE_INDEX_SETTING_UPDATE_SUCCESS: 'File Index Setting successfully updated',
  DATAROOM_CREATING_EXCEL_MESSAGE: 'Your excel is being prepared for download. Depending on total file size and count, this may take several minutes. Once ready, the download will begin automatically.',
  DATAROOM_CREATING_ARCHIVE_MESSAGE: 'Your archive is being prepared for download. Depending on total file size and count, this may take several minutes. Once ready, the download will begin automatically.',
  DATAROOM_DOWNLOAD_FILE_MESSAGE: 'Your download will begin automatically.',
  DATAROOM_MORE_THAN_ONE_LARGE_ARCHIVE_REQUESTED: 'An archive over 1GB is already being created for you. Please allow this to complete before creating another download greater than 1GB.',
  DATAROOM_BUILD_EXPORT_EXPIRED: 'For security reasons, the link you have followed is no longer valid. Please request another export.',
  DATAROOM_BUILD_ARCHIVE_EXPIRED: 'For security reasons, the link you have followed is no longer valid. Please request another archive.',
  DATAROOM_BUILD_NAMED_ARCHIVE_EXPIRED: '{{archiveName}}.zip archive has expired. Please refresh the page',
  DATAROOM_BUILD_EXPORT_NOT_COMPLETED: 'Failed to download not completed build.',
  DATAROOM_BUILD_ARCHIVE_NOT_COMPLETED: 'Failed to download not completed build.',
  DATAROOM_FILE_ACCESS_DENIED: 'Due to changes in your permissions, you no longer have access to this file. Please contact the data room administrator if you feel this is in error.',
  DATAROOM_FILE_NOT_FOUND: 'The file you are attempting to access is no longer available. Please contact the data room administrator if you feel this is in error.',
  DATAROOM_UPLOAD_ISSUES: `We are committed to providing you world class service and support. If you are experiencing upload issues, please ${ CONTACT_US_LINK.toLocaleLowerCase() }.`,
  DATAROOM_CHANGES_SUCCESSFULLY_SAVED: 'Your changes have been successfully saved',
  DATAROOM_ACCESS_REQUEST_SUCCESS: 'Access Request for {{dataroomName}} {{tenantName}} has been submitted. You will receive a response shortly.',
  DATAROOM_FOLLOW_SUCCESS: 'You are now following {{dataroomName}}. Access the document by either downloading it using the action icon or clicking on the deal name to enter the {{tenantName}} room. Alternatively, you can access the deal from your \'{{tabName}}\' tab.',
  DATAROOM_LINK_COPIED: 'Link Copied',
  DATAROOM_DISABLED_FOLDER_MANAGEMENT: 'Due to your permissions, you may only upload files, not folders.',
  DATAROOM_CONDOR_EDIT_SUCCESS: 'You have successfully updated {{name}} Data Room',
  DATAROOM_PREVIEW_CONVERSION_IN_PROGRESS: 'The file is being converted and will be available to preview momentarily. In the meantime, you can download the file if you have permission to do so.',
  DATAROOM_PCR_LIMIT_EXCEEDED: 'These changes cannot be saved as it results in the regulatory entity limit being exceeded.',
  DATAROOM_BUILD_ARCHIVE_MAX_SIZE_LIMIT_REACHED: 'For optimal performance, we do not support download archives over {{maxSize}}GBs.<br />Please create another download below the stated threshold.',
  DATAROOM_REDACT_FILE_SUCCESS: 'Document successfully redacted',
  DATAROOM_APPLY_PENDING_SUCCESS: 'All pending redactions have been successfully applied to {{fileName}}.',
  DATAROOM_REMOVE_REDACTED_FILE_SUCCESS: 'All applied redactions have been successfully removed from {{fileName}}.',
  DATAROOM_UNREDACT_FILE_SUCCESS: 'Document successfully unredacted',
  DATAROOM_UNREDACT_SELECTED_FILES_SUCCESS: 'Redactions have been successfully removed on {{total}} files.',
  DATAROOM_UNREDACT_SELECTED_FILES_WITH_FAILURE: 'Redactions have been removed on {{success}}/{{total}} files. Files that could not be unredacted will remain in Redacted Files tab.',
  DATAROOM_APPLY_REDACTION_SELECTED_FILES_SUCCESS: '{{prefix}} have been successfully redacted. You can remove redactions in the “Redacted Files” tab.',
  DATAROOM_APPLY_REDACTION_SELECTED_FILES_WITH_FAILURE: '{{success}}/{{total}} files have been redacted. Files that could not be redacted will remain in Redaction Area.',
  DATAROOM_STAGE_MOVE_SUCCESS: 'Success! {{prefix}} been moved to the next stage.',

  /*
   * Presentation
   * */
  PRESENTATION_UPLOADING: 'Presentation uploading',
  PRESENTATION_UPLOADED: 'A new presentation has been uploaded.',
  PRESENTATION_UPLOADED_NOTIFY_SUPPORT: '<p style="margin-top:0;margin-bottom:5px;">A new presentation has been uploaded.</p><p style="margin-top: 0;margin-bottom:5px;">To prevent previously synced audio or video from becoming out of sync with presentation, please notify our support team if the slide order or total number of slides has changed.</p>',
  PRESENTATION_MUST_BE_UPLOADED_TO_SAVE: 'Presentation must be uploaded to Save & Publish. Alternatively, you can Save Draft of the roadshow without uploading.',
  PRESENTATION_DOCUMENTS_UPLOADING: 'Deal Documents uploading',
  PRESENTATION_MEDIA_UPLOADING: 'Media uploading',
  PRESENTATION_LOGO_UPLOADING: 'Deal Logo uploading',

  /*
   * Allocations
   * */
  EXCEL_UPLOAD_ERRORS_FOUND: 'We have found {{errorsCount}} {{displayText}}. Please correct XLS template and reupload.',
  DRS_ALLOCATIONS_UPLOADED: 'You successfully uploaded allocations to the <strong>{{roadshowName}}</strong> roadshow!',
  ALLOCATE_ALLOCATIONS_UPLOADED: 'You successfully created the <strong>{{dealAllocationName}}</strong> allocation!',
  ALLOCATE_ALLOCATIONS_UPDATED: 'You successfully updated the <strong>{{dealAllocationName}}</strong> allocation!',
  ALLOCATE_ALLOCATIONS_REIMPORTED: 'You successfully re-imported the <strong>{{dealAllocationName}}</strong> allocation!',
  ACCOUNT_NAME_SUGGESTED: 'Thank you for providing a suggested account name. Our team will promptly review and revert.',
  ACCOUNT_NAME_SUGGESTED_WILL_BE_SAVED: 'Thank you for providing a suggested {{fieldName}} name. After creating your {{tenantName}}, our team will promptly review and revert back.',
  ACCOUNT_NAME_SUGGESTED_CONTACT_US: `Thank you for providing a suggested {{fieldName}} name. Our team will promptly review and revert. Please ${ CONTACT_US_LINK } for further assistance.`,
  ALLOCATE_ACCESS_DENIED: `You do not have access to this allocations. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  ALLOCATE_UPLOAD_ACCESS_DENIED: 'You are not allowed to upload allocations.',
  ALLOCATE_EDIT_ACCESS_DENIED: 'You are not allowed to edit this allocations.',
  CORRECTION_SUBMITTED: 'You correction was successfully submitted.',

  /*
   * Order Book
   * */
  ORDER_BOOK_CREATED: 'You successfully created the <strong>{{orderBookName}}</strong> Order Book.',
  ORDER_BOOK_UPDATED: 'You successfully updated the <strong>{{orderBookName}}</strong> Order Book.',
  ORDER_BOOK_CLOSED: 'You successfully closed the <strong>{{orderBookName}}</strong> Order Book.',
  ORDER_BOOK_REOPENED: 'You successfully re-opened the <strong>{{orderBookName}}</strong> Order Book.',
  ORDERS_SUBMITTED: '{{count}} {{displayText}} successfully added to Order Book.',
  ORDER_MODIFIED: 'The {{displayText}} from {{account}} was successfully modified.',
  ORDER_CONFIRMED: 'The {{displayText}} from {{account}} was successfully confirmed.',
  ORDER_CANCELED: 'The {{displayText}} from {{account}} was canceled.',
  ORDER_DELETED: 'The {{displayText}} from {{account}} was deleted.',
  ORDER_BOOK_ACCESS_DENIED: `You do not have access to this Order Book. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  ORDER_BOOK_EDIT_ACCESS_DENIED: 'You are not allowed to edit this Order Book.',
  INVESTOR_ORDERS_SUBMITTED: 'Your {{displayText}} was successfully submitted to the Deal Managers.',
  INVESTOR_ORDER_SUBMITTED: '{{displayText}} {{index}} was successfully submitted to the Deal Managers.',
  INVESTOR_ORDER_UPDATED: '{{displayText}} {{index}} was successfully updated.',
  INVESTOR_ORDER_CONFIRMED: '{{displayText}} {{index}} has been confirmed.',
  INVESTOR_ORDER_CANCELED: '{{displayText}} {{index}} has been canceled.',

  /*
   * Teams
   * */
  TEAM_CREATED: 'You successfully created the <strong>{{name}}</strong> Team.',
  TEAM_UPDATED: 'You successfully updated the <strong>{{name}}</strong> Team.',
  TEAM_NOT_FOUND: `Team not found. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  TEAM_ACCESS_DENIED: `You do not have access to this Team. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,

  /*
   * Session
   * */
  SESSION_INVALID_CREDENTIALS: 'Invalid email / password combination',
  USER_PASSWORD_CHECK_FAILED: 'Invalid password',
  SESSION_NO_SESSION: `Unable to validate user session. Please attempt to re-login. If this problem persists, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  SESSION_ACCESS_DENIED: `Access is restricted. If you believe this to be an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  SESSION_ATTEMPT_LIMIT: 'Account temporarily disabled due to repeated failed login attempts. Please try again later.',
  SESSION_ACCESS_DENIED_NOT_ADMIN: 'Access denied. This account does not have administrator rights applied. Please contact internal support to troubleshoot.',

  /*
   * User
   * */
  USER_BANNED: `This account has been suspended. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  USER_ALREADY_REGISTERED: 'The email you have entered has already been registered.',
  USER_EMAIL_NOT_VERIFIED: `This email address has not been verified. To resend the verification email, click here. If you believe this is an error, ${ CONTACT_SUPPORT.toLocaleLowerCase() }`,
  USER_ACCOUNT_NOT_ACTIVATED: `Account is not activated. To resend activation email, please <a onclick="execCallback('${ ACTIVATION_EMAIL_RESEND_CALLBACK }','{{callback_params}}')">click here</a>.`,
  USER_ACTIVATION_EMAIL_SENT: 'An activation link has been emailed to {{email}}. Click on the link to activate your account.',
  USER_ACCOUNT_BLOCKED: `Account is blocked. ${ CONTACT_SUPPORT }`,
  USER_ACTIVATION_LINK_NOT_VALID: 'For security reasons, the link you have followed is no longer valid. To request a new activation link, please agree to our terms and continue below.',
  USER_RESET_PASSWORD_HASH_NOT_VALID: 'For security reasons, the link you have followed is no longer valid. Please enter your corporate email to request a new reset password link.',
  USER_INVALIDATE_PASSWORD_SUCCESS: 'You have successfully invalidated the password for {{userNameWithEmail}}!  Support must manually notify contact to change their password.',
  CONTACT_NOT_FOUND: 'This contact does not exist',
  /*
   * Condor
   * */
  CONDOR_TAB_DISABLED: 'Tab {{disabledTab}} is disabled, please enable in {{enableTab}} tab',
  CONDOR_FILES_UPLOADING: 'Files uploading',
  CONDOR_FILE_UPLOADING: 'file {{name}} is uploading',
  CONDOR_NEED_ADD_AUDIO_FOR_SLIDES_AUDIO: 'Need add Audio for "Slides + Audio"',
  CONDOR_NEED_ADD_VIDEO_FOR_SLIDES_VIDEO: 'Need add Video for "Slides + Video"',
  CONDOR_SAVED: 'Changes Saved Successfully',
  CONDOR_APPLY_BRANDING: '"Evercall Branding" is applied!',
  ROADSHOW_NOT_FOUND: 'Roadshow not found',
  CONDOR_RETAIL_LOGO_REQUIRED: 'Logo is required to display roadshow in Retail Table',
  SINGLE_ENTRY_CODE_IS_REQUIRED: 'A valid entry code is required to save changes to this deal. Please access deal via create/edit form to update the entry code and update deal settings',
  DIFFERENT_NUMBER_OF_SLIDES: 'The previous presentation has {{previousCountSlides}} slides, but the current presentation has {{currentCountSlides}} slides. This page allows you to restart the presentation with the same number of slides. If you want to upload this presentation, use the form for deal managers',
  FILE_IS_REQUIRED: 'Deal File is required',
  LINK_IS_REQUIRED: 'External Link is required',
  DELETE_COMPANY_SUCCESS: 'You have successfully deleted {{accountName}}!',
  RESTORE_COMPANY_SUCCESS: 'You have successfully restored {{accountName}}!',
  DELETE_EVERCALL_GROUP_SUCCESS: 'You have successfully deleted group {{groupName}}!',
  ADD_DOMAINS_TO_APPLICATION_BLACKLIST_SUCCESS: 'You have successfully added {{domains}} to the application blacklist!',
  ADD_DOMAINS_TO_GLOBAL_BLACKLIST_SUCCESS: 'You have successfully added {{domains}} to global blacklist!',
  DELETE_APPLICATION_BLACKLIST_ITEM: 'You have successfully deleted {{domain}} from the application blacklist!',
  DELETE_GLOBAL_BLACKLIST_ITEM: 'You have successfully deleted {{domain}} from the global blacklist!',
  CONDOR_DEAL_PROFILE_REQUIRED_TRANCHES_ERROR: (
    '{{tranchesLabel}} required when {{announcedDateLabel}} / {{pricingDateLabel}} is set.'
  ),
  CONDOR_DEAL_PROFILE_DUPLICATE_REGION_REMOVED: (
    'Duplicate region removed from {{additionalRegionsLabel}} field. {{newCountryRegion}} is primary region.'
  ),
  ADD_SUBSCRIBERS_BY_ADMIN: 'Subscriber(s) added successfully',
  REMAPPING_PROCESS_SUCCESS: 'You successfully remapped {{totalCount}} item',
  REMAPPING_PROCESS_SUCCESS_PLURAL: 'You successfully remapped {{totalCount}} items',
  REMAPPING_PROCESS_ERROR: 'An error has occurred and has been reported to our engineering team. Please refresh the page and try again.',
  CONDOR_ACCOUNT_MIGRATION_LOCKED: '{{fromAccountName}} and/or {{toAccountName}} are currently being mapped by another Condor Admin. Please try again in a few minutes.',
  COMPANY_HAS_RELATED_ITEMS: 'Account has related items. Please, refresh page and try again',
  TRACE_TRADE_ARCHIVED_BY_CUSIP_SUCCESS: 'Trades with the CUSIP <strong>{{cusip}}</strong> have been archived!',
  TRACE_TRADE_ARCHIVED_BY_ISSUER_SUCCESS: 'Trades with the Issuer <strong>{{issuerName}}</strong> have been archived!',
  TRACE_TRADE_RESTORED_SUCCESS: 'Trades with the CUSIP <strong>{{cusip}}</strong> have been restored!',
  /*
   * Whitelist
   * */
  WHITELIST_EMAIL_EXIST: '{{email}} is already in whitelist',
  WHITELIST_ACCESS_EMAIL_SENT: 'Access email sent to <strong>{{email}}</strong>',
  WHITELIST_ACCESS_EMAIL_FAILED: `For security reasons, {{email}} cannot be granted access. Please contact ${ FINSIGHT_SUPPORT_EMAIL } or call 1-888-997-6610 with any questions.`,
  WHITELIST_ITEM_DELETE_SUCCESS: 'You successfully deleted <strong>{{emailOrDomain}}</strong> from the whitelist.',
  WHITELIST_WITH_SERIAL_NUMBER_FAILED: 'Domains are not supported with investor-specific serial numbers. Please remove domains and try again.',
  /*
   * Bulk Entry Codes
   * */
  ERROR_MAX_NUMBER_CODES_EXCEEDED: 'Maximum number of entry codes exceeded. Please select fewer dates or increase duration.',
  ERROR_DURATION_NOT_ALIGN_LAST_ENTRY_CODE: 'Please adjust duration or Last Entry Code time so the Last Entry Code time aligns with the selected duration',
  ERROR_INVALID_BASE_CODE: 'Base code must be alphanumeric',

  /*
  * Dm portal applications
  */
  ENABLE_APPLICATION_SUCCESS: 'You have successfully added {{applicationName}} and it is now accessible in the “My Applications” section in the left panel.',
  DISABLE_APPLICATION_SUCCESS: 'You have successfully removed {{applicationName}} from your applications.',

  /*
   * Finsight
   * */
  FINSIGHT_SUBSCRIBE_TO_NEW_15G_FILING_FAILURE: `Unable to subscribe to 15G filing alerts. Please try again or contact <a href="mailto:${ FINSIGHT_SUPPORT_EMAIL }" target="_blank">${ FINSIGHT_SUPPORT_EMAIL }</a> if issue persists.`,
  FINSIGHT_SUBSCRIBE_TO_NEW_15G_FILING_SUCCESS: '{{email}} has been subscribed to receive 15G filing alerts.',
  FINSIGHT_UNSUBSCRIBE_FROM_15G_SUCCESS: '{{email}} has been successfully unsubscribed.',
  FINSIGHT_SUBSCRIPTIONS_SAVE_PREFERENCES_SUCCESS: 'You have successfully saved your email preferences.',
  FINSIGHT_BOND_MANAGER_ACCESS_CONTACT_NOT_FOUND: 'This contact does not exist',
  FINSIGHT_BOND_MANAGER_ACCESS_SAVE_ERROR: 'Unfortunately, the changes were not saved. Please try again or contact support.',
  FINSIGHT_BOND_MANAGER_ACCESS_DUPLICATED_CONTACT: 'This contact already exists as a B&I Manager',
  FINSIGHT_BOND_INVESTOR_ACCESS_STATUS_PENDING: 'Your request for access to BWICs & Inventories has been submitted for review. The review process will take up to 2 business days. We will email updates to <strong>{{email}}.</strong>',
  FINSIGHT_BOND_INVESTOR_ACCESS_STATUS_DENIED: `Your request to access BWICs & Inventories has been denied. Access to this module is predicated on being an Institutional Account as defined by FINRA and is at the discretion of FINSIGHT. If you feel this is an error, please email ${ FINSIGHT_SUPPORT_EMAIL }.`,
  FINSIGHT_FILING_UPDATE_STATUS_ERROR: 'You can\'t disable email sending because the emails have already been sent',
  FINSIGHT_TRACE_INVESTOR_ACCESS_STATUS_PENDING: 'Your request for access to TRACE has been submitted for review. The review process will take up to 2 business days. We will email updates to <strong>{{email}}.</strong>',
  FINSIGHT_TRACE_INVESTOR_ACCESS_STATUS_DENIED: `Your request to access FINSIGHT’s ABS TRACE has been denied. Access to this module is only available to Institutional Accounts as defined by FINRA and is at the discretion of FINSIGHT. If you feel this is an error, please email ${ FINSIGHT_SUPPORT_EMAIL }.`,
  FINSIGHT_PRICING_REQUEST_SERVICE_SUCCESS: 'Thank you! Your request was successfully submitted. Our team will review your request and get back to you within 24 hours.',

  /**
   * SSO
   */
  SSO_ASSERTION_FAIL: `We are unable to process your SSO log in at this time. ${ CONTACT_SUPPORT }<br><br> We apologize for this inconvenience.`,

  /**
   * Two factor authorization
   */
  TWO_FACTOR_CODE_DELIVERY_FAIL: `We are unable to send verification code. ${ CONTACT_SUPPORT }`,
  TWO_FACTOR_CODE_EXPIRED: 'Verification code has expired.',
  TWO_FACTOR_CODE_INVALID_VALUE: 'Invalid verification code',
  TWO_FACTOR_FAIL_ATTEMPT: 'Invalid verification code',
  TWO_FACTOR_INVALID_MOBILE_PHONE: `The mobile phone number entered cannot receive SMS text messages. Please enter a different phone number or ${ CONTACT_US_LINK } our fanatical 24x7 support to troubleshoot`,

  /**
   * Q&A
   */
  QNA_QUESTIONS_CREATE_SUCCESS: 'Your {{questionsLabel}} been successfully submitted to the Q&A Manager',
  QNA_QUESTIONS_BULK_UPLOAD: '{{questionsLabel}} successfully uploaded',
  QNA_QUESTIONS_EDIT_SUCCESS: 'Changes saved successfully',
  QNA_QUESTIONS_RESOLVE: '{{questionsLabel}} been resolved.',
  QNA_QUESTIONS_REOPEN: '{{questionsLabel}} been re-opened.',
  QNA_QUESTIONS_REMOVE_SUCCESS: '{{questionsLabel}} been deleted.',
  DATAROOM_QNA_QUESTION_LIMIT_REACHED: 'Question limit exceeded. Your Questions were not submitted',
  DATAROOM_QNA_QUESTION_LIMIT_EXCEEDED: 'Question limit surpassed. Please remove excess questions and re-upload file.',
  QNA_CATEGORY_CREATE_SUCCESS: '“{{categoryName}}” category has been created.',
  QNA_CATEGORY_DELETE_SUCCESS: '“{{categoryName}}” category has been deleted.',
  QNA_CATEGORY_NAME_DUPLICATE: 'Category name already exists',
  QNA_QUESTION_NOT_FOUND: 'This question has been deleted',
  QNA_ACCESS_DENIED: 'You do not have access to view this question',
  QNA_BULK_UPLOAD_ERROR: 'We have found {{errors}}. Please correct XLS template and reupload.',
  DATAROOM_REDACTION_NO_SCANNABLE_FILES_SELECTED: 'No scannable files selected. Note, only PDF files (excluding password protected PDFs and PDFs over 100MB) can be scanned for keywords.',
  DATAROOM_REDACTION_SCAN_BY_KEYWORD_STARTED: 'Redaction scanning in process. Files will be added to the Redaction Area as they are scanned. Please refresh the page to see results.',

  /**
   * FILE UPLOAD
   */
  ROADSHOW_FILE_RESTRICTED: 'Sorry, this file type is not permitted for security reasons',
  UPLOAD_HAS_FAILED: 'Upload has failed',

  /*
   * OpenMarket
   * */
  OPEN_MARKET_PROFILE_CREATED: 'You successfully created the <strong>{{profileName}}</strong> OpenMarket Profile',
  OPEN_MARKET_PROFILE_UPDATED: 'You successfully updated the <strong>{{profileName}}</strong> OpenMarket Profile',
  OPEN_MARKET_PROFILE_NAME_ALREADY_EXISTS: 'The Profile Name you entered already exists. Please enter different Profile Name',
  OPEN_MARKET_PROFILE_ALIAS_ALREADY_EXISTS: 'The Profile Url you entered already exists. Please enter different Profile Url',
  OPEN_MARKET_EVENT_CREATED: 'You successfully created the <strong>{{eventName}}</strong> OpenMarket Event',
  OPEN_MARKET_EVENT_UPDATED: 'You successfully updated the <strong>{{eventName}}</strong> OpenMarket Event',

  // CRM
  CRM_NOTE_HAS_BEEN_DELETED: 'Your Note has been successfully deleted',
};

const messageCodes = Object.keys(messages)
  .reduce((buf, key) => {
    buf[key] = key;
    return buf;
  }, {} as typeof messages);

export default messages;

export { messageCodes };
